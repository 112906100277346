<template>
	<div>
		<b-card title="Rekomendasi Service">
			<b-row class="mb-2">
				<b-col cols="12" md="6">
					<router-link
						to="/create-rekomendasi-service"
						variant="primary"
						class="btn waves-effect waves-float waves-light btn-primary"
						v-if="checkPermission('add recomendation feature')"
					>
						Add Rekomendasi
					</router-link>
				</b-col>
				<!-- <b-col cols="12" md="6">
					<form class="form-inline justify-content-end">
						<div class="d-flex">
							<div class="position-relative">
								<input 
									type="search"
									class="form-control"
									v-model="filter.keyword" 
									placeholder="Search: Name Article" 
									aria-label="Search"
								>
							</div>
							<button 
								:disabled="!filter.keyword" 
								@click="filter.keyword = ''" 
								class="btn btn-outline-secondary ml-sm-2"
							>
								Reset
							</button>
						</div>
					</form>
				</b-col> -->
			</b-row>
			<b-table 
				striped 
				hover 
				:items="recomendation" 
				:fields="fields"
				:busy="isLoading"
				show-empty
				responsive
			>
				<template #table-busy>
					<div class="text-center text-secondary my-2">
						<b-spinner class="align-middle"></b-spinner>
						<strong>Loading...</strong>
					</div>
				</template>
				
				<template v-slot:cell(no)="{ index }">
					{{ (currentPage - 1) * 10 + index + 1 }}
				</template>

				<template v-slot:cell(fitur)="{ item }">
					<div v-for="feature in item.features" :key="feature.uuid">
						{{ feature.name }}
					</div>
				</template>

				<template v-slot:cell(status)="{ item }">
					<b-badge
						variant="light-success"
						v-if="item.status == 'active'"
					>
						Aktif
					</b-badge>
					<b-badge
						variant="light-danger"
						v-if="item.status == 'inactive'"
					>
						Tidak Aktif
					</b-badge>
				</template>
				<template v-slot:cell(actions)="{ item }">
					<div class="d-flex" style="gap:12px;">
						<router-link
							:to="'/edit-rekomendasi-service/'+ item.uuid"
							v-if="checkPermission('update article')"
						>
							<b-badge 
								variant="warning"
								class="badge-glow"
							>
								<feather-icon
									:id="`invoice-row-${item.uuid}-send-icon`"
									icon="EditIcon"
									class="cursor-pointer"
									size="16"
									v-ripple.400="'rgba(113, 102, 240, 0.15)'"
									v-b-tooltip.hover.bottom="'Edit Data'"
								/>
							</b-badge>
						</router-link>
						
						<b-badge 
							variant="danger"
							class="badge-glow"
						>
							<feather-icon
								:id="`invoice-row-${item.uuid}-preview-icon`"
								icon="TrashIcon"
								size="16"
								v-ripple.400="'rgba(113, 102, 240, 0.15)'"
								v-b-tooltip.hover.bottom="'Delete Data'"
								v-if="checkPermission('delete article')"
								@click="deleteItem(item.uuid)"
							/>
						</b-badge>
					</div>
				</template>
			</b-table>
			<div
				v-if="result.total > 0"
				class="m-1"
			>
				<div class="row">
					<div class="col mb-1">
						<small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
					</div>
					<div class="col">
						<pagination
							:data="result"
							:limit="4"
							align="right"
							@pagination-change-page="getData"
						/>
					</div>
				</div>
			</div>
		</b-card>
	</div>
</template>

<script>
import { 
	BCard,
	BCardText,
	BTable,
	BPagination,
	BBadge,
	BButton,
	BSpinner,
	BRow,
	BCol,
	VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import _ from 'lodash'
export default {
	components: {
		BCard,
		BCardText,
		BTable,
		BPagination,
		BButton,
		BSpinner,
		BBadge,
		BRow,
		BCol
	},
	directives: {
	'b-tooltip': VBTooltip,
		Ripple,
	},
	watch: {
		filter: {
			handler: _.debounce(function () {
				this.getData()
			}, 300),
			deep: true,
		},
	},
	
	setup(props) {
		return {
			checkPermission,
		}
	},
	data() {
		return {
			currentPage: 1,
			result: {},
			isLoading: false,
			fields: ['no', 'name', 'price', 'fitur', 'actions'],
			recomendation: [],
			filter: {
				keyword: ''
			},
			// sort: {}
		}
	},

	computed: {
		rows() {
			return this.recomendation.length
		}
	},

	methods: {
		getData(page) { //page = 1
			this.isLoading = true
			const queryParams = this.filter
			queryParams.per_page = 10
			queryParams.page = page
			this.$http.get('admin/features/recomendations', {
				params: queryParams,
			})
				.then(response => {
				this.result = response.data.data
				this.recomendation = response.data.data.data
				console.log(this.recomendation);
				this.isLoading = false
				this.currentPage = response.data.data.current_page
				})
		},
		deleteItem(uuid) {
			this.$swal({
				title: 'Apakah Anda yakin?',
				icon: 'info',
				text: 'Anda akan menghapus Rekomendasi Service ini. Data yang sudah terhapus tidak dapat dipulihkan.',
				type: 'warning',
				showCancelButton: true,
				customClass: {
					confirmButton: 'btn btn-primary',
					cancelButton: 'btn btn-outline-danger ml-1',
				},
				confirmButtonText: 'Ya, hapus data ini',
				cancelButtonText: 'Batal',
				buttonsStyling: false
			}).then(async result =>{
				if(result.value) {
					await this.$http.delete('admin/features/recomendations/' + uuid)
					this.getData(this.currentPage)
					successNotification(this, 'Success', 'Rekomendasi Service berhasil dihapus!')
				}
			}).catch(error => {
				errorNotification(this, 'Oops!', 'Ada Kendala Teknis')
			})
		}
	},

	created() {
		this.getData();
	}
}
</script>

<style>
	
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"title":"Rekomendasi Service"}},[_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"cols":"12","md":"6"}},[(_vm.checkPermission('add recomendation feature'))?_c('router-link',{staticClass:"btn waves-effect waves-float waves-light btn-primary",attrs:{"to":"/create-rekomendasi-service","variant":"primary"}},[_vm._v(" Add Rekomendasi ")]):_vm._e()],1)],1),_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.recomendation,"fields":_vm.fields,"busy":_vm.isLoading,"show-empty":"","responsive":""},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-secondary my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true},{key:"cell(no)",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s((_vm.currentPage - 1) * 10 + index + 1)+" ")]}},{key:"cell(fitur)",fn:function(ref){
var item = ref.item;
return _vm._l((item.features),function(feature){return _c('div',{key:feature.uuid},[_vm._v(" "+_vm._s(feature.name)+" ")])})}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [(item.status == 'active')?_c('b-badge',{attrs:{"variant":"light-success"}},[_vm._v(" Aktif ")]):_vm._e(),(item.status == 'inactive')?_c('b-badge',{attrs:{"variant":"light-danger"}},[_vm._v(" Tidak Aktif ")]):_vm._e()]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex",staticStyle:{"gap":"12px"}},[(_vm.checkPermission('update article'))?_c('router-link',{attrs:{"to":'/edit-rekomendasi-service/'+ item.uuid}},[_c('b-badge',{staticClass:"badge-glow",attrs:{"variant":"warning"}},[_c('feather-icon',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('Edit Data'),expression:"'Edit Data'",modifiers:{"hover":true,"bottom":true}}],staticClass:"cursor-pointer",attrs:{"id":("invoice-row-" + (item.uuid) + "-send-icon"),"icon":"EditIcon","size":"16"}})],1)],1):_vm._e(),_c('b-badge',{staticClass:"badge-glow",attrs:{"variant":"danger"}},[(_vm.checkPermission('delete article'))?_c('feather-icon',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('Delete Data'),expression:"'Delete Data'",modifiers:{"hover":true,"bottom":true}}],attrs:{"id":("invoice-row-" + (item.uuid) + "-preview-icon"),"icon":"TrashIcon","size":"16"},on:{"click":function($event){return _vm.deleteItem(item.uuid)}}}):_vm._e()],1)],1)]}}])}),(_vm.result.total > 0)?_c('div',{staticClass:"m-1"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col mb-1"},[_c('small',[_vm._v("Showing "+_vm._s(_vm.result.from)+" to "+_vm._s(_vm.result.to)+" from "+_vm._s(_vm.result.total))])]),_c('div',{staticClass:"col"},[_c('pagination',{attrs:{"data":_vm.result,"limit":4,"align":"right"},on:{"pagination-change-page":_vm.getData}})],1)])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }